<template>
  <div class="inter-wrapper">
    <HeaderInter />
    <!-- 填寫資料 -->
    <div class="inter-content">
      <div class="form-area">
        <p v-if="query_title && query_title !== 'undefined'">
          {{ query_title }}
        </p>
        <p v-else-if="orderDetail">
          訂單編號:{{ orderDetail.order_num }}
        </p>
        <p v-else-if="query_order_num">
          訂單編號: {{ query_order_num }} 問題回報
        </p>
        <div v-else>
          <input
            v-model="contactForm.title"
            v-validate="'required'"
            type="text"
            class="box"
            placeholder="標題"
            name="title"
            data-vv-as="標題"
          >
          <div v-if="errors.has('title')" class="help-block">
            {{ errors.first('title') }}
          </div>
        </div>
        <input
          v-model="contactForm.from_name"
          v-validate="'required'"
          type="text"
          class="box"
          placeholder="姓名"
          name="from_name"
          data-vv-as="姓名"
        >
        <div v-if="errors.has('from_name')" class="help-block">
          {{ errors.first('from_name') }}
        </div>

        <input
          v-model="contactForm.from_email"
          v-validate="'required'"
          type="email"
          class="box"
          placeholder="Email"
          name="from_email"
          data-vv-as="Email"
        >
        <div v-if="errors.has('from_email')" class="help-block">
          {{ errors.first('from_email') }}
        </div>

        <textarea
          v-model="contactForm.content"
          v-validate="'required'"
          rows="8"
          class="form-control mb-3"
          placeholder="問題內容"
          name="content"
          data-vv-as="問題內容"
        />
        <div v-if="errors.has('content')" class="help-block">
          {{ errors.first('content') }}
        </div>

        <button class="btn btn-next" type="button" @click="contactSubmit()">
          確認送出
        </button>
      </div>
    </div>
    <b-modal
      id="modal-ok"
      title=""
      ok-only
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <div class="alert-area container-fluid">
        <div class="icon-content-o">
          <b-icon icon="envelope-open" class="icon-content" />
        </div>
        <p class="text-ctener">
          感謝您的聯繫<br>我們會盡快回覆您!
        </p>
      </div>
      <template #modal-footer="{ hide }">
        <b-button
          size="sm"
          variant="outline-secondary"
          @click="hideModal('modal-ok')"
        >
          close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getOrder } from '@/api/order'
import { addContact } from '@/api/shop'
import HeaderInter from '@/components/HeaderInter'

export default {
  name: 'ContactAsk',
  components: {
    HeaderInter
  },
  data() {
    const contactForm = {
      from_name: null,
      from_email: null,
      content: null,
      order_id: null,
      title: null
    }

    return {
      orderDetail: false,
      contactForm: contactForm,
      order_num: this.$route.params.order_num,
      query_order_num: this.$route.query.order_num,
      query_title: decodeURIComponent(this.$route.query.title) || null
    }
  },
  created() {
    if (this.query_order_num) {
      this.contactForm.title = `訂單編號: ${this.query_order_num} 問題回報`
    }

    if (this.order_num && this.$store.getters.authInfo.token) {
      getOrder(this.order_num, {}).then(response => {
        this.orderDetail = response.data.data
      })
    }
    // if title then auto replace
    if (this.query_title && this.query_title !== 'undefined') {
      this.contactForm.title = this.query_title
    }
  },
  mounted() {},
  methods: {
    contactSubmit() {
      const self = this
      if (this.orderDetail) {
        this.contactForm.order_id = this.orderDetail.order_id
      }

      this.$validator.validateAll().then(valid => {
        if (valid) {
          addContact(this.contactForm)
            .then(response => {
              if (response.data.succ) {
                self.showModal('modal-ok')
              }
            })
            .catch(() => {})
        }
      })
    },
    showModal(id) {
      this.$bvModal.show(id)
    },
    hideModal(id) {
      this.contactForm.content = null
      this.$router.go(this.$router.currentRoute)
      this.$bvModal.hide(id)
    }
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/common.scss';

.form-area {
  display: flex;
  flex-direction: column;
  .btn {
    flex: 0 0 100%;
    font-size: 1rem;
    border-radius: 0;
    padding: 10px;
  }
  .btn-next {
    background: rgba($main-color, $alpha: 1);
    color: $white-color;
  }
}
</style>
